import axios from 'axios'
import store from '@/store/store.js'
import qs from 'qs'
import { dev_api, pro_api } from '@/siteInfo.js'
console.log(process.env)
const domain = process.env.NODE_ENV == 'development' ? dev_api : pro_api
import { message } from 'ant-design-vue'
import router from '../router/router.js'
console.log(domain)
const get = (params = {}) => {
	let t = this
	let data = params.data || {}
	// data.appid = APP_ID
	// data.appsecret = APP_SECRET
	data.token = store.state.user.token
	console.log('yu ming:', domain[params.port])
	return new Promise((resolve, reject) => {
		axios({
			url: params.url,
			params: params.data,
			baseURL: domain[params.port],
			transformRequest: [
				function(data) {
					return qs.stringify(data)
				}
			],
			headers: params.headers ? params.headers : {}
		})
			.then(res => {
				console.log(domain[params.port] + params.url + ':', res.data)
				console.log(res.data.code == -10)
				if (res.data.code == -10) {
					console.log(123131312)
					router.push({ path: '/login' })
				}
				resolve(res.data)
			})
			.catch(err => {
				console.log(domain[params.port] + params.url + ':', err)
				message.error('网络开了小差')
				reject(err)
			})
	})
}
const post = (params = {}) => {
	let t = this
	let data = params.data || {}
	data.token = store.state.user.token
	console.log('yu ming:', domain[params.port])
	return new Promise((resolve, reject) => {
		axios({
			url: params.url,
			data: params.data,
			method: 'post',
			baseURL: domain[params.port],
			transformRequest: [
				function(data) {
					return qs.stringify(data)
				}
			],
			headers: params.headers ? params.headers : {}
		})
			.then(res => {
				console.log(domain[params.port] + params.url, res.data)
				if (res.data.code == -10) {
					console.log(123131312)
					router.push({ path: '/login' })
				}
				resolve(res.data)
			})
			.catch(err => {
				console.log(domain[params.port] + params.url + ':', err)
				message.error('网络开了小差')
				reject(err)
			})
	})
}
export { get, post }
