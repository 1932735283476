import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
Vue.use(Router)
export const routes = [{
		path: '/index/index',
		component: () => import('@/view/index/index'),
		name: 'Index',
		parent: '首页',
		meta: {
			title: '西湖自主军转',
			noCache: true,
			permission: ''
		}
	},{
		path: '/user',
		component: () => import('@/view/user/user'),
		name: 'User',
		parent: '用户管理',
		meta: {
			title: '用户管理',
			noCache: true,
			roles: [0],
			permission: ''
		}
	},
	{
		path: '/notice',
		component: () => import('@/view/notice/notice'),
		name: 'Notice',
		parent: '公告通知',
		meta: {
			title: '公告通知',
			noCache: true,
			permission: ''
		}
	}, {
		path: '/news',
		component: () => import('@/view/news/news'),
		name: 'News',
		parent: '新闻资讯',
		meta: {
			title: '新闻列表',
			noCache: true,
			permission: ''
		}
	},{
		path: '/study/class',
		component: () => import('@/view/study/class/class'),
		name: 'Class',
		parent: '学习园地',
		meta: {
			title: '栏目分类',
			noCache: true,
			permission: ''
		}
	},{
		path: '/study/article',
		component: () => import('@/view/study/article/article'),
		name: 'Article',
		parent: '学习园地',
		meta: {
			title: '文章列表',
			noCache: true,
			permission: ''
		}
	},
	{
		path: '/study/activeAlbum',
		component: () => import('@/view/study/activeAlbum/activeAlbum'),
		name: 'ActiveAlbum',
		parent: '学习园地',
		meta: {
			title: '活动相册',
			noCache: true,
			permission: ''
		}
	},
	{
		path: '/system/mini',
		component: () => import('@/view/system/mini/mini'),
		name: 'Mini',
		parent: '系统管理',
		meta: {
			title: '小程序配置',
			noCache: true,
			permission: ''
		}
	},
	{
		path: '/system/swiper',
		component: () => import('@/view/system/swiper/swiper'),
		name: 'Swiper',
		parent: '系统管理',
		meta: {
			title: '轮播配置',
			noCache: true,
			permission: ''
		}
	},
	// {
	// 	path: '/system/nav',
	// 	component: () => import('@/view/system/nav/nav'),
	// 	name: 'Nav',
	// 	parent: '系统管理',
	// 	meta: {
	// 		title: '导航图标',
	// 		noCache: true,
	// 		permission: ''
	// 	}
	// },
	{
		path: '/about',
		component: () => import('@/view/about/about'),
		name: 'About',
		parent: '关于我们',
		meta: {
			title: '关于我们',
			noCache: true,
			roles: [0],
			permission: ''
		}
	},
	{
		path: '/questionnaire',
		component: () => import('@/view/questionnaire/questionnaire'),
		name: 'Questionnaire',
		parent: '问卷调查',
		meta: {
			title: '问卷调查',
			noCache: true,
			roles: [0],
			permission: ''
		}
	},
	{
		path: '/ueditor',
		component: () => import('@/view/questionnaire/ueditor'),
		name: 'Ueditor',
		parent: 'Ueditor',
		meta: {
			title: 'Ueditor',
			noCache: true,
			roles: [0],
			permission: ''
		}
	},
	{
		path: '/active',
		component: () => import('@/view/questionnaire/active'),
		name: 'Active',
		parent: '活动报名',
		meta: {
			title: '活动报名',
			noCache: true,
			roles: [0],
			permission: ''
		}
	}
]
const threeRoutes = [
	{
		path: '/questionnaire/addQuestionnaire',
		component: () => import('@/view/questionnaire/addQuestionnaire'),
		name: 'addQuestionnaire',
		parent: '问卷调查',
		meta: {
			title: '新增问卷',
			noCache: true,
			permission: ''	
		}
	},
	{
		path: '/questionnaire/addActive',
		component: () => import('@/view/questionnaire/addActive'),
		name: 'AddActive',
		parent: '活动报名',
		meta: {
			title: '修改通知',
			noCache: true,
			permission: ''	
		}
	},
	{
		path: '/notice/addNotice',
		component: () => import('@/view/notice/addNotice'),
		name: 'AddNotice',
		parent: '公告通知',
		meta: {
			title: '新增通知',
			noCache: true,
			permission: ''	
		}
	},
	{
		path: '/study/addImgs',
		component: () => import('@/view/study/activeAlbum/addImgs'),
		name: 'AddImgs',
		parent: '学习园地',
		meta: {
			title: '新增相册',
			noCache: true,
			permission: ''	
		}
	},
	{
		path: '/news/addNews',
		component: () => import('@/view/news/addNews'),
		name: 'AddNews',
		parent: '新闻资讯',
		meta: {
			title: '新增资讯',
			noCache: true,
			permission: ''	
		}
	},
	{
		path: '/study/addArticle',
		component: () => import('@/view/study/article/addArticle'),
		name: 'AddArticle',
		parent: '学习园地',
		meta: {
			title: '新增文章',
			noCache: true,
			permission: ''	
		}
	},
	{
		path: '/system/addSwiper',
		component: () => import('@/view/system/swiper/addSwiper'),
		name: 'AddSwiper',
		parent: '系统管理',
		meta: {
			title: '新增轮播',
			noCache: true,
			permission: ''	
		}
	},
	{
		path: '/cmsUser',
		component: () => import('@/view/cmsUser/cmsUser'),
		name: 'CmsUser',
		parent: '人员管理',
		meta: {
			title: '人员管理',
			noCache: true,
			permission: ''	
		}
	}
]
// ,
// 	{
// 		path: '/system/addNav',
// 		component: () => import('@/view/system/nav/addNav'),
// 		name: 'AddImgs',
// 		parent: '系统管理',
// 		meta: {
// 			title: '新增导航',
// 			noCache: true,
// 			permission: ''	
// 		}
// 	}
export const initRoutes = [{
	path: '/',
	component: Layout,
	redirect: '/index/index',
	name: 'Index',
	meta: {
		title: '西湖自主军转',
		icon: 'location'
	},
	children: routes.concat(threeRoutes)
}, {
	path: '/login',
	component: () => import('@/view/login/login'),
	name: 'Login',
	meta: {
		title: '登录',
		noCache: true,
		roles: [0]
	}
}]
const createRouter = () => new Router({
	mode: 'history', // require service support
	base: '/',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: initRoutes
})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
export default router
