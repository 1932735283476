const dev_api = {
	a: 'https://jcapi.mtree.cn/api/admin/'
}
const pro_api = {
	a: 'https://jcapi.mtree.cn/api/admin/'
} 
const domain = {
	a: 'https://jcapi.mtree.cn/'
}

const imglink = 'https://jcimg.mtree.cn/api/getImg?APP_ID=wx7965fd9ef5cfab6d&APP_SECRET=97b1ad0494bb9a21f63191bfeb0bf673&img_id='
const APP_ID = 'wx7965fd9ef5cfab6d';
const APP_SECRET = '97b1ad0494bb9a21f63191bfeb0bf673';
export {
	dev_api,
	pro_api,
	APP_ID,
	APP_SECRET,
	imglink,
	domain
}
