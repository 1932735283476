import router from './router/router'
import store from './store/store'
import { message } from 'ant-design-vue'
const allowList = ['/login']

router.beforeEach((to, from, next) => {
	let token = store.state.user.token;
	console.log('to de ye mian ', to)
	if (allowList.indexOf(to.path) > -1) {
		if (to.meta.title) {
			console.log(to.meta.title)
			document.title = to.meta.title
		}
		next()
	} else if (token) {
		console.log(to.meta.title)
		if (to.meta.title) {
			document.title = to.meta.title
		}
		next()
	} else {
		document.title = '登录'
		next({ path: '/login' })
	}
})
