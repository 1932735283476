<template>
	<a-menu mode="inline" theme="dark" @openChange="onOpenChange">
		<a-menu-item key="0">
			<router-link to="/user">用户管理</router-link>
		</a-menu-item>
		<a-menu-item key="1">
			<router-link to="/news">新闻资讯</router-link>
		</a-menu-item>
		<a-menu-item key="2">
			<router-link to="/notice">公告通知</router-link>
		</a-menu-item>
		<a-menu-item key="3">
			<router-link to="/questionnaire">问卷调查</router-link>
		</a-menu-item>
		<a-menu-item key="8">
			<router-link to="/active">活动报名</router-link>
		</a-menu-item>
		<a-sub-menu keys="4">
			<span slot="title">
			  <a-icon type="mail" />
			  <span>学习园地</span> 
			</span>
			<a-menu-item key="4-0">
				<router-link to="/study/class">栏目分类</router-link>
			</a-menu-item>
			<a-menu-item key="4-1">
				<router-link to="/study/article">文章列表</router-link>
			</a-menu-item>
			<a-menu-item key="4-2"> 
				<router-link to="/study/activeAlbum">活动相册</router-link>
			</a-menu-item>
		</a-sub-menu>
		<a-sub-menu keys="5">
			<span slot="title">
			  <a-icon type="mail" />
			  <span>系统管理</span> 
			</span>
			<a-menu-item key="5-0">
				<router-link to="/system/mini">小程序配置</router-link>
			</a-menu-item>
			<a-menu-item key="5-1">
				<router-link to="/system/swiper">轮播图</router-link>
			</a-menu-item>
		<!-- 	<a-menu-item key="5-2">
				<router-link to="/system/nav">导航图标</router-link>
			</a-menu-item> -->
			<a-menu-item key="5-3">
				<router-link to="/about">关于我们</router-link>
			</a-menu-item>
		</a-sub-menu>
		<a-menu-item key="7" v-if="is_admin">
			<router-link to="/cmsUser">人员管理</router-link>
		</a-menu-item>
	</a-menu>
</template>

<script>
	export default {
		data() {
			return {
				rootSubmenuKeys: [],
				openKeys: [],
				selectKey: [],
				hideOpenKeys: [],
				hideSelectKey: []
			}
		},
		computed: {
			is_admin() {
				return this.$store.state.user.is_admin
			}
		},
		created() {
			
		},
		mounted() {
			
		},
		methods:{
			onOpenChange(openKeys) {
			    const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
			    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			        this.openKeys = openKeys;
			    } else {
			        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
			    }
			}
		}
	}
</script>

<style lang="less">
</style>
