<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {
			
		}
	}
</script>

<style lang="less">
	@import './static/style.less';
	
</style>
<style>
	@import './static/quil/quill.core.css';
	@import './static/quil/quill.bubble.css';
	@import './static/quil/quill.snow.css';
</style>