	<template>
	<div>
		<a-upload
			action="https://jcimg.mtree.cn/api/setImg"
			:multiple="multiple"
			list-type="picture-card"
			:file-list="fileList"
			@preview="handlePreview"
			@change="handleChange"
			:data="{
				APP_ID: APP_ID,
				APP_SECRET: APP_SECRET
			}"
		>
			<div v-if="fileList.length < max"><a-icon type="plus" /></div>
		</a-upload>
		<a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
			<img alt="example" style="width: 100%" :src="previewImage" />
		</a-modal>
	</div>
</template>

<script>
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}
import { APP_ID, APP_SECRET } from '@/siteInfo.js';
export default {
	name: 'Upload',
	props: {
		img_id: {
			type: String,
			default: '1' 
		},
		max: {
			type: Number,
			default: 1
		},
		multiple: {
			type: Boolean,
			default: false
		},
		img_id_list: {
			type: Array,
			default() {
				return [];
			}
		},
		setId:{
			type: Boolean,
			default: false
		}
	},
	watch: {
		// img_id(val) {
		// 	console.log(val);
		// 	if(val == '') {
		// 		this.fileList = []
		// 	}else{
		// 		this.formatFileList()
		// 	}
		// },
		setId(val) {
			if(val) {
				console.log(val)
			}
		}
	},
	created() {
		console.log(this.img_id)
		let t = this
	},
	mounted() {
		console.log('dsadasdas',this.img_id)
		this.formatFileList()
	},
	data() {
		return {
			APP_ID: APP_ID,
			APP_SECRET: APP_SECRET,
			previewVisible: false,
			previewImage: '',
			fileList: []
		};
	},
	methods: {
		formatFileList() {
			let t = this
			if(this.img_id == '') {
				this.fileList = []
				return
			}
			let arr = this.img_id.split(',')
			console.log('arr', arr)
			let fileList = []
			for(let i = 0;i<arr.length;i++) {
				let item = arr[i]
				let data = {
					uid: i,
					status: 'done',
					img_id: item,
					url: t.$imglink+item
				}
				fileList.push(data)
			}
			this.fileList = fileList
		},
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		handleCancel() {
			this.previewVisible = false;
		},
		handleChange(e) {
			this.fileList = e.fileList;
			if(e.file.status == 'done') {
				this.$emit('upImgId', e.fileList);
			}
			// if (e.file.status == 'done') {
			// 	let imgList= []
			// 	console.log()
			// 	for(let i = 0; i < this.fileList.length;i++) {
			// 		let item = this.fileList[i]
			// 		console.log('shangchuan::', item)
			// 		// if(item.response) {
			// 		// 	if (item.response.code == 0) {
			// 		// 		imgList.push(item.response.data.img_id);
			// 		// 	}
			// 		// } else {
			// 		// 	imgList.push(item.img_id)
			// 		// }
			// 	}
			// 	// this.$emit('upImgId', imgList);
			// } else if (e.file.status == 'removed') {
			// 	let imgList= []
			// 	for(let i = 0; i < this.fileList.length;i++) {
			// 		let item = this.fileList[i]
			// 		if(item.response) {
			// 			if (item.response.code == 0) {
			// 				imgList.push(item.response.data.img_id);
			// 			}
			// 		} else {
			// 			imgList.push(item.img_id)
			// 		}
			// 	}
			// }
		}
	}
};
</script>

<style lang="less"></style>
