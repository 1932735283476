import Vue from 'vue'
import App from './App.vue'

import Ant from 'ant-design-vue';

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)


// import 'amis/sdk/sdk.js';
// import amis from 'amis/embed'
Vue.use(Ant)
import store from './store/store.js'
import { get, post } from '@/utils/fetch.js'
import 'ant-design-vue/dist/antd.less';
import { imglink } from '@/siteInfo.js'
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$imglink = imglink
// import store from './store/store'
import router from './router/router'
Vue.config.productionTip = false
import './permission'
import upload from '@/components/upload/upload.vue'

import zhCN from 'ant-design-vue/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

Vue.component("upload", upload)
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
