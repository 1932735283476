<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <div class="app-sidebar">
      <div class="logo-wrapper">
        <div class="logo-box">
          <div class="company-name">西湖自主军转</div>
        </div>
        <Sidebar />
      </div>
    </div>
    <a-layout class="app-container" style="display:block">
      <a-layout-header style="padding: 0;background: #fff;">
        <div class="header-control">
          <a-button
            type="primary"
            style="margin-right: 10px"
            @click="showEditModal"
            >修改密码</a-button
          >
          <a-popconfirm
            title="确认退出登录吗"
            ok-text="是"
            cancel-text="否"
            @confirm="logout()"
          >
            <a-button type="danger">退出</a-button>
          </a-popconfirm>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '24px 16px 0',
          overflow: 'initial',
          'min-height': 'calc(100vh - 88px)',
        }"
      >
        <transition name="fade-transform" mode="out-in">
          <!-- <keep-alive> -->
          <router-view />
          <!-- </keep-alive> -->
        </transition>
      </a-layout-content>
    </a-layout>
    <a-modal
      v-model="showPass"
      title="修改密码"
      :confirmLoading="sendIng"
      :cancelText="'取消'"
      :okText="'提交'"
      @ok="editPass"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item
          has-feedback
          label="原密码"
          prop="password"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-input v-model="form.password" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item
          has-feedback
          label="新密码"
          prop="newPassword"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-input
            v-model="form.newPassword"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item
          has-feedback
          label="确认密码"
          prop="rePassword"

          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-input v-model="form.rePassword"  type="password" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-layout>
</template>
<script>
import Sidebar from "./sidebar/sidebar.vue";
export default {
  name: "Layout",
  components: {
    Sidebar,
  },
  data() {
    return {
      collapsed: true,
      showPass: false,
      sendIng: false,
      form: {
        password: "",
        newPassword: "",
        rePassword: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
          {
            min: 8,
            max: 16,
            message: "请输入8位密码",
            trigger: "blur",
          },
        ],
        rePassword: [
          {
            required: true,
            message: "请确认密码",
            trigger: "blur",
          },
          {
            min: 8,
            max: 16,
            message: "请输入8位密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    logout() {
      this.$store.commit("user/logout");
      this.$router.push({ path: "/login" });
    },
    showEditModal() {
      this.showPass = true;
    },
    editPass() {
      let t = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          t.sendIng = true;
          t.$post({
            port: "a",
            url: "upUserPassword",
            data: t.form,
          })
            .then((res) => {
              let { code, data, msg } = res;
              t.showPass = false;
              if (code == 0) {
                t.$message.success("您已重置密码，即将跳转重新登录", 1.5);
                t.$store.commit("user/logout");
                setTimeout(() => {
                  t.$router.push({ path: "/login" });
                  t.sendIng = true;
                }, 1200);
              } else {
                t.$message.error(msg, 1.5);
                t.sendIng = true;
              }
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
.header-control {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  padding-right: 20px;
}
#components-layout-demo-fixed-sider {
  flex-direction: row;
}
.ant-layout-sider {
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.25);
}
.ant-layout-sider::-webkit-scrollbar {
  display: none;
}
.app-sidebar {
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.25);
  width: 256px;
  flex: 0 0 256px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: #001529;
  .logo-wrapper {
    padding: 16px 0;
  }
  .logo-box {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 35px;
    overflow: hidden;
    flex-wrap: wrap;
    .logo-img {
      width: 40px;
      img {
        width: 100%;
      }
    }
    .company-name {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin-left: 10px;
      white-space: nowrap;
    }
  }
}
.app-container {
  flex-grow: 1;
  margin-left: 256px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  min-height: 100vh !important;
}
.app-sidebar {
  .ant-menu-inline-collapsed {
    width: 100%;
  }
}
.collapse-sidebar {
  width: 80px;
  .logo-box {
    .company-name {
    }
  }
}
.scale-container {
  margin-left: 80px;
}

.ant-layout-content {
  background-color: #fff;
  padding: 20px;
}

.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
