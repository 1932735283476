import router, {
	resetRouter
} from '@/router/router.js'
import axios from 'axios'
import Cookies from 'js-cookie'

let currentRole = Cookies.getJSON("currentRole")
let userInfo = Cookies.getJSON("userInfo")
let token = Cookies.get("token")
let roleList = Cookies.get("roleList")
let is_admin = Cookies.getJSON("is_admin")
console.log('huancun', is_admin)
import {domain} from '@/siteInfo.js'
import {post,get} from '@/utils/fetch.js'
console.log("geren store", currentRole)
const state = {
	token: token ? token : false,
	userInfo: userInfo ? userInfo : false,
	roleList: roleList ? roleList : [],
	currentRole:  currentRole ?  currentRole : false,
	is_admin: is_admin ? is_admin : false
}

const mutations = {
	setUserInfo(state, info) {
		state.userInfo = info;
		Cookies.set("userInfo", info)
	},
	setToken(state, token) {
		state.token = token;
		Cookies.set('token', token)
	},
	logout(state) {
		Cookies.remove('userInfo')
		Cookies.remove('token')
		state.userInfo = false
		state.token = false
	},
	setIsAdmin(state, booer) {
		state.is_admin = booer
		Cookies.set("is_admin", booer)
	}
}

const actions = {
	login({commit}, params) {
		let t = this;
		return new Promise((resolve, reject) => {
			post({
				port:'a',
				url: 'login', 
				data: params,
			}).then(res => {
				let {
					code,
					msg,
					data
				} = res;
				if (code == 0) {
					if(data) {
						commit('setUserInfo',data)
						commit('setToken',data.token)
						commit('setIsAdmin',data.is_admin)
						resolve(res)
					}else{
						resolve({msg:'请完成初始化',code:999,data:false})
					}
				}else{
					resolve(res)
				}
			}).catch(err => {
				console.log(err)
				reject(err)
			})
		})

	}
}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}
